#root{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
html, body{
  height: 100%;
  margin: 0;
  padding: 0;
}

p{
  font-family: 'Poppins', sans-serif;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  line-height: 1.5;
  font-size: .93em;
  color: #5f5f5f;
}

span{
  font-family: 'Times New Roman', Times, serif;
  justify-self: center;
  font-weight: bold;
}
.bottom-text-container{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.bottom-text-container > p{
  width: 70%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.footer{
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
  padding-bottom: 0 !important;
}
.footer > p{
  padding-bottom: 0;
  margin-bottom: 0;
}
h3{
  font-family: sans-serif;
}
.category-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.coupon-categories-container{
  width: 1300px;
  display: flex;
  flex-direction: column;

  align-items: center;
}
.coupon-list-container{
  width: 1300px;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding-top:30px;
  padding-bottom: 30px;
}
.contact-form-container{
  display: flex;
  justify-content:flex-start;
  align-items:flex-start;
  width: 1000px;
  flex-direction: column;
}
.contact-form-container > h2{
  margin-left: 12%;
}
.contact-form{
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1000px;
}
.contact-form > div{
  border: thin solid #cacaca;
  border-radius: 7px;
  width: 70%;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

input{
  height: 40px;
  font-size:medium;
  border: 0;
  width: 95%;
  background: none;
  color: black;
  margin-left: 5px;
}
#message{
  border: thin solid #cacaca;
  border-radius: 7px;
  min-height: 200px;
  min-width: 69%;
  font-size: 18px;
  background: none;
  color: black;
  padding-top: 10px;
  padding-left: 10px;
}

input:focus {outline:none!important;}

@media (max-width: 1300px){
  .coupon-categories-container, .coupon-list-container{
    width: 100%;
  }
  .coupon-list{
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 99% !important;
  }
  .contact-form-container{
    width: 100%;
  }
  .contact-form{
    width: 80%;
  }
}
@media (max-width: 868px){
  .card-category-container > .card{
    flex-direction: row;
  }
}

.text{
  text-decoration: none;
  
  padding-bottom: 10px;
  padding-left: 7px;
}
.img-container > .text{
  text-decoration: none;
  color: #333;
}
.categories{
  display: flex;
  gap: 10px;
}

.favs{
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.card{
  display: flex;
  flex-direction: column;
  width: calc(20% - 20px);
  height: 280px;
  margin-right: 15px;
  margin-left: 0;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: thin solid #CCCCCC;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

  max-height: 280px;
  overflow-y: hidden;
}
@media (max-width: 1168px) {
  .card {
    width: 22vw; /* For a 2-column layout on smaller screens */
  }
}
@media (max-width: 868px) {
  .card {
    width: 100%;
    height: auto;
    border: none;
    box-shadow: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}
  .card > a{
    display: flex;
    flex-direction: row;
  }
  .coupon-header{
    font-size: medium !important;
  }
  .top{
    border: thin solid #CCCCCC;
    border-radius: 5px;
    height: 200px !important;
  }
  .bottom{
    border-top-style: none !important;
    border-width: 0;
  }
}

.card-text{
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
  line-height: 2; /* Adjust line height to minimize vertical spacing */
  font-size: 1rem;
  font-weight: bold;
  padding-top: 20px;
  
}
.bottom > .link > span{
  color: #f51000 !important;
}
p.card-text{
  color: hsl(0, 0%, 41%);
  font-size: 0.8rem;

}

.container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.top-img{
  height: 120px;
}
.categories > div{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #87CEEB;
  width: 100px;
  height: 100px;
  padding: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.big-deals{
  display: flex;
  width: 100%;
  justify-content:space-between;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 20px;
  height: 200px;
}
.big-deals::-webkit-scrollbar {
  width: 0.1rem; /* Adjust this value to make it as small as possible */
}
.big-deals::-webkit-scrollbar-thumb {
  background: transparent;
}
.big-deals-container{
  display: flex;
  width: 380px;
  height: 170px;
  box-shadow: -1px 4px 12px -1px gray;
  border-radius: 10px;
  background-color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.deals-section{
  background-color: #b9d6f0;
  background-image: url('./images/header-image.jpg');
  /* Adjust the size and position of the background image */
  background-size: cover; /* or contain, based on your preference */
  background-position: center center; /* or any other position */
  
}
.deals-section h2, .deals-section p {
  color: white;
}
.affiliate-section{
  background-color: #93f593;
}
.affiliate-section > h2{
  color: #006400;
}
@media (max-width: 768px) {
  
  .big-deals::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .big-deals-container {
    width: 100vw;
    border: #c2c0c0;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
  }
  .img-container{
    height: 100%;
  }
  .big-deals-img{
    height: 100%;
    width: 25vw !important;
  }
  .big-deals-imgcontainer{
    width: 100% !important;
  }
}
.amazon-img{
  width: 150px;
}
.big-deals-img{
  width: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.img-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.big-deals-imgcontainer{
  width: 100%;
}
body{
  background-color: white;
}

#page-name-container{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232F3E;
  color: white;
  /*background-image: url('./images/nav-image.jpg');*/ /* Replace 'your-image.jpg' with the path to your JPG image */
  background-size: cover; /* Cover the entire div with the background image */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;
  font-size: 30px;
  font-weight: bolder;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 5vh;
}

.search-container{
  top: 50%;
  left: 50%;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  height:100%;
  background:  white;
  
}

img{
  height: 100%;
}

.category-item{
  color: #333;
  font-size: .99em;
}

a{
  text-decoration: none;
}
a:hover{
  /*color: #0778f1;*/
}
button {
  width: 200px;
  background-color: #0074D9; /* Your desired blue color */
  color: #fff; /* Text color */
  border: none;
  padding: 10px 20px; /* Adjust the padding as needed */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
  font-size: 16px; /* Adjust the font size */
  transition: background-color 0.3s; /* Add a smooth color transition */

  /* Hover effect */
  &:hover {
    background-color: #0056A5; /* A slightly darker shade of blue on hover */
  }

  /* You can add more styles as needed */
}

.search-bar {
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  
  display: flex;
  width: 100%;
  align-items: center;
  height: 45px;
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  justify-content: space-around;
}

.search-input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
}

.search-button {
  padding: 8px 15px;
  background-color: #f57c00;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  width: 50px;
  display: flex;
  margin-right: 5px;
  border-radius: 40px !important;
  -webkit-border-radius: 40px !important;
  -moz-border-radius: 40px !important;
  -ms-border-radius: 40px !important;
  -o-border-radius: 40px !important;
}
.menu-item{
  padding-right: 10px;
  
  display: flex;
}
.icon{
 
}
.left-coupon-container{
  padding-bottom: 30px;
}

@media (max-width: 1468px) {
  .left-coupon-container{
    width: 100%;
  }
  .side-coupon-img{
    width: 100%;
  }
}

@media (max-width: 768px) {
  .stores-dropdown > span{
    color: black !important;
  }
  .link-in-dropdown > span{
    color: black !important;
  }
  .menu-items{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    z-index: 1;
    padding: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 !important;
    overflow: hidden;
    transition: max-height .3s, opacity 0.3s, padding 0.3s, transform 0.11s;
    max-height: 0;
    opacity: 0;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    -webkit-transition: max-height .3s, opacity 0.3s, padding 0.3s, transform 0.11s;
    -moz-transition: max-height .3s, opacity 0.3s, padding 0.3s, transform 0.11s;
    -ms-transition: max-height .3s, opacity 0.3s, padding 0.3s, transform 0.11s;
    -o-transition: max-height .3s, opacity 0.3s, padding 0.3s, transform 0.11s;
  }
  .menu-items.active{
    gap: 50px;
    max-height: 700px; /* Adjust the height to your preference */
    opacity: 1;
    transform: translateY(0);
    justify-content: center;
    align-items: center; 
  }
  
  .dropdown{
    margin-top: 10px !important;
    transition: max-height 0.3s, opacity 0.3s, padding 0.3s;
    max-height: 0;
    opacity: 0;
    transform: translateY(-100%);
  }
  .dropdown.active{
    max-height: 700px;
    /*Adjusttheheighttoyourpreference*/opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  
}
/* Show the menu items when .active class is applied */
.menu-items.active {
  display: block;
}
.menu-item.active{
  display: block;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.coupon-image{
  width: 100px;
  height: 100%;
}
.card-container{
  display: flex;
  flex-direction: row;
  background-color: white;
  flex-wrap: wrap;
  justify-content: space-between;
}
.top{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
}
.bottom{
  border-style: solid none none none;
  border-width: 1px;
  border-color: rgb(202, 202, 209);
  display: flex;
  width: 96%;
  padding-left: 10px;
  height: 150px;
}

.aff-stores{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content:space-between;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 20px;
}
.aff-stores > a{
  background-color: white;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
/* Hide the horizontal scrollbar */
.aff-stores::-webkit-scrollbar {
  width: 0.1rem; /* Adjust this value to make it as small as possible */
}
.aff-stores::-webkit-scrollbar-thumb {
  background: transparent;
}
.stores{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  padding: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: thin solid #CCCCCC;
}
.aff-stores-img{
  width: 90%;
}

.aff-stores-img > div{
  width: 100%;
}

.coupon-card {
  background: #FFFFFF;
  border: 2px solid #FFFFFF;
  padding: 15px;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  height: 120px;
  width: 40vw;
  margin: 10px;
  font-family: Arial, sans-serif;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.coupon-card > div{
  height: 100%;
}
.coupon-card > div > a{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  height: 100%;
}
.search-page-container{
  width: 1300px;
}
.divider {
  width: 1px;
  height: 120%;
  background-color: #e0d9d9; /* Color of the line */
  margin: 0 10px; /* Adjust the spacing of the line */
}
.coupon-header {
  color: black; /* Red text for the label and category */
  font-size: 18px;
  
}

.coupon-description {
  color: #333; /* Dark gray text for the description */
  font-size: 1.3rem;
  margin-top: 10px;
}

.coupon-footer {
  color: #555; /* Light gray text for expiration date */
  font-size: 12px;
  margin-top: 10px;
}

.coupon-list{
  width: 90%;
  padding: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: rgb(248, 246, 246);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: thin solid #CCCCCC;
}

.store-logo{
  height: 100px;
  width: 290px;
  margin-bottom: 40px;
  border: thin solid #CCCCCC;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

@media (max-width: 768px) {
  .category-section {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}


/*********** Nav Section ************/
#nav-bar-container{
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  flex-direction: column;
  width: 100%;
  height: 300px;
  
}
.nav-page-name-container{
  text-decoration: none;
  color: white;
  
}
.page-name{
  font-size: 2rem;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
  transform: skewX(-15deg);
  /*box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);*/
  height: inherit;
  margin: 0 !important;
}

#Nav{
  background-image: url('./images/header-image.jpg');
  /* Adjust the size and position of the background image */
  background-size: cover; /* or contain, based on your preference */
  background-position: center center; /* or any other position */
  /* Other styles for your #Nav div */
  flex-direction: column;
  position: relative;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  font-size: 17px;
  margin-bottom: 30px;
  box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.2);
  background-color: #232F3E;
  width: 100%;
  height: 50vh;
  padding: 0 20px; /* Add horizontal padding to prevent content from being too close to the edges on smaller screens */
  justify-content: center;
}
.top-header{
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}
.search-page-container > .container{
  display: flex;
  justify-content: flex-start;
}
.bottom-header{
  display: flex;
  padding-bottom: 20px;
  width: 45vw;
  color: white;
}
@media (max-width: 1000px){
  #Nav{
    padding: 20px;
  }
  .bottom-header{
    width: 450px;
  }
}
@media (max-width: 500px){
  .top-header{
    padding-bottom: 0;
  }
  .bottom-header{
    width: 90vw;
    padding-bottom: 20px;
  }
  #Nav{
    justify-content: center !important;
  }
  
  .menu-item > h2{
    margin-top: 0;
  }
  .card{
    max-height: 350px;
  }
  .card > a{
    display: block;
  }
}
#home{
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.link{
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  max-height: 150px;
  width: 100%;
  overflow-y: hidden;
  cursor: pointer;
}
.link > .card-text{
  color: black;
}
.dropdown.active{
  display: block;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

}

.menu-items {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  
}

@media (max-width: 768px) {
  #Nav {
    justify-content: space-around;
  }
  
}
@media (min-width: 768px) {
  .menu-items.active{
    display: flex !important;
    gap: 50px;
    max-height: 700px; /* Adjust the height to your preference */
    opacity: 1;
    transform: translateY(0);
    justify-content: center;
    align-items: center;  
  }
}
.nav-page-name{
  font-size: 1.4rem;
  font-family:'Times New Roman', Times, serif;
  transform: skewX(-15deg);
}
.dropdown{
  position: absolute;
  display: none;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  margin-top: 30px;

  /* Adjusted styles for smaller screens */
  @media (max-width: 768px) {
    min-width: auto;
    width: 100%; /* Make the dropdown full width for small screens */
    top: 100%; /* Position below the parent element on small screens */

  }

}
.menu-icon{
  display: none;
}
@media (max-width: 768px) {
  .dropdown.active{
    position: relative;
    width: 100%;
    padding-right: 0;
    margin-right: 0;
    box-shadow: none;
  }
  .dropdown .link {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-left: 25px;
    width: auto;
  }
  /* Hide stores-dropdown and other divs in the mobile view */
  .menu-item{
    display: none;
  }
  .menu-icon {
    display: block;
    margin: 10px;
    color: white;
  }
  .coupon-card{
    width: 80vw;
  }
  .stores-dropdown > span{
    color: #333 !important;
  }
  .stores-dropdown{
    color: #333 !important;
  }
  .link > span{
    color: #333 !important;
  }
}

link:visited{
  text-decoration: none;
}
link:active{
  text-decoration: none;
}
.dropdown .link{
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 15px;
  text-decoration: none;
}

.stores-dropdown{
  display: flex;
  align-items: center;
}

/*********** Home Section ************/
.home{
  width: 1300px;
  /*width: 100%;*/
}
.home-text{
  padding-bottom: 10px;
}

.main-coupon-container{
  border-radius: 30px;
  height: 350px;
  display: flex;
  padding-bottom: 80px;
  width: 100%;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}


.main-coupon-link{
  height: 100%;
  width: 100%;
}
.main-coupon:hover{
  box-shadow: 3px 4.5px 10px rgba(0, 0, 0, 0.3);
}
.main-coupon{
  height: 100%;
  display: flex;
  width: 100%;
  border-radius: 30px;
  transition: box-shadow 0.3s; /* Add a transition for the box-shadow property */

  /* Initial box shadow (no shadow) */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.main-coupon > div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: black;
  width: 300px;
  color: white;
}


.main-img{
  border-radius: 30px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.side-img{
  border-radius: 30px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 35%;
  display: block;
}

.home > div{
  padding-bottom: 60px;
}
.stores-dropdown > span{
  color: white;
}

@media (max-width: 1300px) {
  .home {
    width: 95%; /* Adjust the container to full width for smaller screens */
  }
  .search-page-container{
    width: 95%;
  }
  .home-text {
    padding-bottom: 10px; /* Reduce padding for smaller screens */
  }

  .main-coupon-container {
    border-radius: 15px; /* Reduce border radius for smaller screens */
    height: auto; /* Let the height adjust based on content */
    padding: 20px 0; /* Adjust padding for smaller screens */
  }

  .main-coupon-link {
    height: auto; /* Let the height adjust based on content */
    width: 100%; /* Set width to 100% for smaller screens */
  }

  .main-coupon:hover {
    box-shadow: 3px 4.5px 10px rgba(0, 0, 0, 0.3);
  }

  .main-coupon {
    border-radius: 15px; /* Reduce border radius for smaller screens */
    transition: box-shadow 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    height: 400px;
  }

  .main-img {
    border-radius: 15px; /* Reduce border radius for smaller screens */
    width: 65%;
  }

  .main-coupon > div {
    padding: 10px; /* Adjust padding for smaller screens */
    width: 35%;
  }

  .side-img {
    display: none;
  }
  .coupon-description {  
    font-size: .93rem;
    width: 30vw;
    height: 130%;
  }
}
/*********** Coupons *****************/

.card-category-container{
  display: flex;
  flex-wrap: wrap;
}